import { baseEnvironment } from './base-environment';

export const environment = {
  ...baseEnvironment,
	production: false,
	clientId: '16867oefojr499vaupbtrbh9lp',
	redirectUri: 'https://admin.wallet-dev.bcr.gob.sv/home',
	logoutUri: 'https://pool-wallet-des.auth.us-east-1.amazoncognito.com/',
	issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_FMnbSzNNa',
	poolId: 'us-east-1_FMnbSzNNa',
	env: 'DEV',
	urlServer: 'https://backend.admin.wallet-dev.bcr.gob.sv',
}
